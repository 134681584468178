import { Container, Grid, Paper, Typography, Tabs, Tab, Box, Chip, Button, Tooltip } from '@mui/material';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../services/apiContext';
import ObsListDefinitionList from '../../components/ObsListDefinitionList';
import { IList, IMedia } from '../../schemas/interfaces';
import CopyToClipboard from '../../components/map/CopyToClipboard';
import ObsItemsTable from '../../components/ObsItemsTable';
import MasonryImage from '../../components/gallery/MasonryImage';
import Lightbox from '../../components/gallery/Lightbox';
import { locationFormatter } from '../../services/dataFormatters';
import { ListObservationMode, ListRoles, ListTypes } from '../../schemas/enums';
import Masonry from 'react-responsive-masonry';
import ObservationMap from '../../components/map/ObservationMap';
import ItemActionsButton from '../../components/ItemActionsButton';
import NotFound from '../../components/NotFound';
import Loading from '../../components/Loading';
import SecrecyLock from '../../components/dicts/SecrecyLock';
import { ShareOutlined } from '@mui/icons-material';
import { useDict } from '../../services/dictContext';

interface ListAppParams {
    id: string;
}

enum ItemViewAppTabs {
    Map = 'Map',
    Photo = 'Photo',
}

const PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS = 7;

interface ListAppState extends ListAppParams {
    activeTab?: ItemViewAppTabs;
    list?: IList;
    media?: IMedia[];
    notFound: boolean;
    loadAllPhotos: boolean;
    lightboxOpenOnPicture?: number;
    addingComment?: boolean;
}

export const ListViewApp: React.FunctionComponent = () => {
    const params = useParams();
    const [state, setState] = React.useState<ListAppState>({
        id: params.id,
        loadAllPhotos: false,
    } as ListAppState);
    const api = useApi();
    const { places } = useDict();

    useEffect(() => {
        api.getObsListAPI(state.id)
            .then((response) => {
                const media = response.items.map((item) => item.media).flat();

                setState((state) => ({
                    ...state,
                    media,
                    list: response,
                    activeTab:
                        media && media.length > 0
                            ? ItemViewAppTabs.Photo
                            : response.location.coordinates
                            ? ItemViewAppTabs.Map
                            : undefined,
                }));
            })
            .catch((e) => {
                console.log(e);
                setState((state) => ({ ...state, notFound: true }));
            });
    }, [state.id]);

    if (state.notFound)
        return <NotFound text={`Vycházka s\xa0ID ${state.id} nebyla nalezena.`} className="ObsListViewApp" />;

    if (!state.list) return <Loading fullPage />;

    const { role, type, secrecyLevel, location, publicId, observationMode } = state.list;

    const locationId = state.list.location?.territorialUnitId;

    const media = state.media || [];

    return (
        <Container maxWidth="lg" className="ListViewApp" sx={{ pt: 5.75, pb: 6.75 }}>
            <Paper elevation={4} sx={{ py: 3, px: 2.5 }}>
                <Grid container spacing={0} columnSpacing={3.5}>
                    <Grid
                        item
                        xs={12}
                        sx={{ pb: 1.75, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                    >
                        <Typography variant="h2" component="h1">
                            {!places ? (
                                'Loading...'
                            ) : !location ? (
                                `Unknown location ID ${locationId}`
                            ) : (
                                <>
                                    <strong>
                                        {locationFormatter(state.list.siteName, locationId, places)}
                                    </strong>
                                    <SecrecyLock
                                        secretUntil={state.list.secretUntil}
                                        secrecyLevel={secrecyLevel}
                                        SvgIconProps={{ sx: { position: 'relative', top: '.3rem' } }}
                                        TooltipProps={{ placement: 'right' }}
                                    />
                                </>
                            )}
                        </Typography>
                        <ItemActionsButton list={state.list} />
                    </Grid>
                    <Grid container item xs={12} md={6} spacing={2.25} alignContent="flex-start">
                        <Grid item xs={12} md={6}>
                            <CopyToClipboard
                                label="ID vycházky"
                                value={String(publicId)}
                                copyValue={`https://cso.cz/${publicId}`}
                                customIcon={<ShareOutlined fontSize="small" />}
                                copiedMessage="Odkaz pro&nbsp;sdílení zkopírován do&nbsp;schránky!"
                                typographyProps={{
                                    variant: 'body2',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'flex-end' }}>
                                {type == ListTypes.complete && <Chip label="Kompletní seznam" />}
                                {type == ListTypes.partial && <Chip label="Částečný seznam" />}
                                {type == ListTypes.selected && <Chip label="Kompletní pro vybrané druhy" />}
                                {role == ListRoles.owner && <Chip label="Jste autorem" />}
                                {role == ListRoles.coobserver && <Chip label="Jste spolupozorovatelem" />}
                                {observationMode == ListObservationMode.area && (
                                    <Tooltip title="Pozorovatel procházel a zkoumal určenou oblast.">
                                        <Chip label="Oblast" />
                                    </Tooltip>
                                )}
                                {observationMode == ListObservationMode.point && (
                                    <Tooltip title="Pozorování probíhalo z jednoho pevného bodu.">
                                        <Chip label="Bod" />
                                    </Tooltip>
                                )}
                                {observationMode == ListObservationMode.line && (
                                    <Tooltip title="Pozorovatel se pohyboval po zadané trase.">
                                        <Chip label="Linie" />
                                    </Tooltip>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} variant="outlined" sx={{ pt: 1, pl: 1 }}>
                                <ObsListDefinitionList list={state.list} hideColumns={['location']} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <ObsItemsTable items={state.list.items} hideColumns={['ageId', 'activityId']} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} alignContent="flex-start" sx={{ pt: [2, 0] }}>
                        <Tabs
                            value={state.activeTab}
                            onChange={(e, newValue) => setState({ ...state, activeTab: newValue })}
                            indicatorColor="secondary"
                            variant="fullWidth"
                            textColor="inherit"
                            sx={{ bgcolor: 'primary.main', color: 'white', width: '100%' }}
                        >
                            <Tab label="Fotografie" value={ItemViewAppTabs.Photo} disabled={media.length < 1} />
                            <Tab label="Mapa" value={ItemViewAppTabs.Map} disabled={!location.coordinates} />
                        </Tabs>
                        <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                            <TabPanel currentValue={state.activeTab} thisValue={ItemViewAppTabs.Photo}>
                                {media.length > 0 && (
                                    <MasonryImage
                                        image={media[0]}
                                        sx={{ maxHeight: '400px', width: '100%' }}
                                        hideIcon
                                        disableDialog
                                        onClick={() => setState((state) => ({ ...state, lightboxOpenOnPicture: 0 }))}
                                    />
                                )}
                                {media.length > 1 && (
                                    <Box sx={{ pt: 2.25, width: '100%' }}>
                                        <Masonry gutter="1.125rem" columnsCount={2}>
                                            {media
                                                .slice(
                                                    1,
                                                    state.loadAllPhotos
                                                        ? undefined
                                                        : PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS,
                                                )
                                                .map((image, index) => (
                                                    <MasonryImage
                                                        image={image}
                                                        key={image.id}
                                                        hideIcon
                                                        disableDialog
                                                        onClick={() =>
                                                            setState((state) => ({
                                                                ...state,
                                                                lightboxOpenOnPicture: index + 1,
                                                            }))
                                                        }
                                                        sx={{
                                                            maxHeight: '200px',
                                                        }}
                                                    />
                                                ))}
                                        </Masonry>
                                        {!state.loadAllPhotos &&
                                            media.length > PHOTO_COUNT_BEFORE_CLICKING_SHOW_ALL_PHOTOS - 1 && (
                                                <Box
                                                    sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
                                                >
                                                    <Button
                                                        size="small"
                                                        onClick={() =>
                                                            setState((state) => ({ ...state, loadAllPhotos: true }))
                                                        }
                                                        variant="contained"
                                                        sx={{ mt: 3 }}
                                                    >
                                                        Zobrazit všechny fotografie
                                                    </Button>
                                                </Box>
                                            )}
                                    </Box>
                                )}
                                <Lightbox
                                    images={media}
                                    open={state.lightboxOpenOnPicture !== undefined}
                                    openOnImage={state.lightboxOpenOnPicture}
                                    onClose={() =>
                                        setState((state) => ({ ...state, lightboxOpenOnPicture: undefined }))
                                    }
                                />
                            </TabPanel>
                            <TabPanel currentValue={state.activeTab} thisValue={ItemViewAppTabs.Map}>
                                <ObservationMap list={state.list} />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

const TabPanel: FunctionComponent<{
    currentValue?: ItemViewAppTabs;
    thisValue: ItemViewAppTabs;
    children: ReactNode;
}> = (props) => {
    return (
        <Box
            sx={{
                display: props.currentValue === props.thisValue ? 'flex' : 'none',
                py: 2.25,
                height: '100%',
                width: '100%',
                flexWrap: 'wrap',
                boxSizing: 'border-box',
            }}
        >
            {props.currentValue === props.thisValue && props.children}
        </Box>
    );
};
