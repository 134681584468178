import sleep from './sleep';
import { IObsUser } from '../interfaces/IObsUser';
import * as z from 'zod';
import { ICoordinates, ITaxon, IUserMessage } from '../schemas/interfaces';
import {
    request_observations,
    request_observations_export,
    request_observations_lists,
    response_codebook_attributes,
    response_codebook_projects,
    response_codebook_territorialunits,
    response_observations_filters,
    response_observations_lists,
    schema_taxon,
    response_list_id,
    request_list_item_comment,
    response_web_news_language,
    response_web_news_language_id,
    response_web_help_language,
    response_web_faq_language,
    response_web_about_language,
    response_observations_export,
    response_user_profile_id,
    request_user_message_id,
    response_success,
    request_list,
    response_list,
    response_media_id,
    response_list_item_comment,
    request_observations_media,
    response_observations_media,
    request_count,
    response_count,
    request_listWithoutItems,
    schema_listItem_input,
    request_observations_mapPoints,
    response_observations_mapPoints,
    response_web_projects_language,
    request_analytics_countDate,
    response_analytics_countDate,
    response_user,
    request_list_report,
    response_success_uuid,
    request_user_filter,
    response_import,
    schema_geojsonFeatureCollection,
    request_observations_map,
} from '../schemas/schemas';
import {
    RsGetDicts,
    RsGetProjects,
    RsGetPlaces,
    RsGetExport,
    RsGetObservationsLists,
    RsGetListById,
    RsSuccess,
    RsGetAllNewsArticles,
    RsGetNewsArticle,
    RsGetHelpSections,
    RsGetFaqSections,
    RsGetAboutSections,
    RsGetUserProfileById,
    RsLogin,
    RsIdentity,
    RsRefresh,
    RsPostList,
    RsGetMedia,
    RsPostMedia,
    RsGetObservationsGallery,
    RsGetObservationsTable,
    response_observations_table,
    RsGetList,
    RsPostItemComment,
    RsGetSearchCount,
    RsGetObservationsMap,
    RsGetProjectsInfo,
    RsGetCharts,
    RsUpdateItemComment,
    RsGetUser,
    RsSuccessUuid,
    RsPostImport,
    RsGetObservationsMapNew,
} from '../schemas/responses';
import {
    RqGetObservations,
    RqGetExport,
    RqGetObservationsLists,
    RqPostItemComment,
    RqPostObservationsList,
    RqLogin,
    RqGetObservationsGallery,
    RqGetSearchCount,
    RqPutList,
    RqPostListItem,
    RqGetObservationsMap,
    RqGetCharts,
    RqUpdateItemComment,
    RqPostListReport,
    RqPostListItemReport,
    RqPostUserFilter,
    RqGetObservationsMapNew,
} from '../schemas/requests';
import { request_token, response_identity, response_refresh, response_token } from '../schemas/schemas_auth';
import { ACCESS_TOKEN_LIFESPAN, AuthContextProps } from './authenticator';
import mem from 'mem';
import { addMilliseconds } from 'date-fns';
import { EExportMode } from '../schemas/enums';

const DEVAPI_LS_KEY = 'avifUseDevApi';
const useDevApi = window.localStorage && !!window.localStorage.getItem(DEVAPI_LS_KEY);

const apiAvifUrl: string = (useDevApi ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_URL) ?? 'api';
const apiLoginUrl: string =
    (useDevApi ? process.env.REACT_APP_API_DEV_LOGIN_URL : process.env.REACT_APP_API_LOGIN_URL) ?? 'api-login';
const apiGeocodeUrl: string =
    (useDevApi ? process.env.REACT_APP_API_DEV_GEOCODE_URL : process.env.REACT_APP_API_GEOCODE_URL) ?? 'api-geocode';

let refreshFunction: Promise<RsRefresh> | undefined = undefined;
let lastMemoized: Date | null = null;

const apiFactory = (auth?: AuthContextProps) => {
    const refreshToken = async (failOnError?: boolean) => {
        if (
            !refreshFunction ||
            !lastMemoized ||
            lastMemoized < addMilliseconds(new Date(), -1 * ACCESS_TOKEN_LIFESPAN)
        ) {
            refreshFunction = postAuthRefresh();
            lastMemoized = new Date();
        }

        await refreshFunction?.catch((error) => {
            if (failOnError) throw new Error('User is not logged in.');
            if (auth?.isLoggedIn) auth?.actions?.logout && auth.actions.logout(true);
        });
    };

    /**
     * Wrapper for fetch GET/DELETE requests. Supports checking the response code and the format of returned data.
     * If the response code is not 200, the promise is rejected. If the response is not a valid JSON, the promise is rejected.
     *
     * @param path The path to the resource.
     * @param method GET or DELETE
     * @param schema The schema that the response should be validated against.
     * @param mockingApi If true, the request is sent to the mock API. If false, the request is sent to the real API.
     * @returns Promise that resolves to the parsed response.
     */
    async function fetchGetInner<T>(
        path: string,
        schema?: z.ZodTypeAny,
        apiUrl: string = apiAvifUrl,
        method: 'GET' | 'DELETE' = 'GET',
        acceptedStatus = [200],
        customHeaders?: HeadersInit,
    ): Promise<T> {
        const headers = { ...getHeaders(), ...customHeaders };

        return new Promise((resolve, reject) => {
            fetch(`${apiUrl}${path}`, { headers: headers, method: method, credentials: 'include' })
                .then((response) => {
                    if (!acceptedStatus.includes(response.status)) return reject(response);

                    response
                        .json()
                        .then((data) => {
                            if (schema) {
                                try {
                                    return resolve(schema.parse(data));
                                } catch (err) {
                                    return reject(err);
                                }
                            } else {
                                return resolve(data as T);
                            }
                        })
                        .catch((err) => {
                            return reject(new Error(`Response is not a valid JSON.`, err));
                        });
                })
                .catch((err) => reject(err));
        });
    }

    async function fetchGet<T>(
        path: string,
        schema?: z.ZodTypeAny,
        apiUrl: string = apiAvifUrl,
        method: 'GET' | 'DELETE' = 'GET',
        acceptedStatus = [200],
        customHeaders?: HeadersInit,
    ): Promise<T> {
        return refreshToken().then(() => fetchGetInner<T>(path, schema, apiUrl, method, acceptedStatus, customHeaders));
    }

    /**
     * Wrapper for fetch POST requests. Supports checking the response code and the format of input and output data.
     * If the response code is not 200, the promise is rejected. If the request body or response is not a valid JSON, the promise is rejected.
     *
     * @param path The path to the resource.
     * @param inputData The body of the request.
     * @param inputSchema The schema that `inputData` should be validated against.
     * @param outputSchema The schema that the response should be validated against.
     * @returns Promise that resolves to the parsed response.
     */
    async function fetchPostInner<TInput, TOutput>(
        path: string,
        inputData: TInput,
        inputSchema?: z.ZodTypeAny,
        outputSchema?: z.ZodTypeAny,
        apiUrl = apiAvifUrl,
        method: 'POST' | 'PUT' = 'POST',
        acceptedStatus = [200],
        customHeaders?: HeadersInit,
    ): Promise<TOutput> {
        const isUpload = inputData instanceof FormData;
        const headers = { ...getHeaders(isUpload), ...customHeaders };

        return new Promise((resolve, reject) => {
            if (inputSchema) {
                if (!inputSchema.safeParse(inputData).success) {
                    reject(new Error(`Request is not a valid JSON.`));
                }
            }
            fetch(`${apiUrl}${path}`, {
                headers: headers,
                method: method,
                body: isUpload ? inputData : JSON.stringify(inputData),
                credentials: 'include',
            })
                .then((response) => {
                    if (!acceptedStatus.includes(response.status)) return reject(response);

                    response
                        .json()
                        .then((data) => {
                            if (outputSchema) {
                                return outputSchema.safeParseAsync(data).then((res) => {
                                    if (res.success) return resolve(res.data);
                                    return reject(res);
                                });
                            } else {
                                return resolve(data as TOutput);
                            }
                        })
                        .catch((err) => {
                            return reject(new Error(`Response is not a valid JSON.`, err));
                        });
                })
                .catch((err) => reject(err));
        });
    }

    async function fetchPost<TInput, TOutput>(
        path: string,
        inputData: TInput,
        inputSchema?: z.ZodTypeAny,
        outputSchema?: z.ZodTypeAny,
        apiUrl = apiAvifUrl,
        method: 'POST' | 'PUT' = 'POST',
        acceptedStatus = [200],
        customHeaders?: HeadersInit,
    ): Promise<TOutput> {
        return refreshToken().then(() =>
            fetchPostInner<TInput, TOutput>(
                path,
                inputData,
                inputSchema,
                outputSchema,
                apiUrl,
                method,
                acceptedStatus,
                customHeaders,
            ),
        );
    }

    const getHeaders = (skipContentType?: boolean): HeadersInit => {
        const headers: HeadersInit = {};

        headers['Accept'] = 'application/json';

        if (!skipContentType) headers['Content-Type'] = 'application/json';

        // authorization header is disabled since the cookies are used instead
        // if (auth?.token) headers['Authorization'] = `Bearer ${auth.token}`;

        return headers;
    };

    const login = (username: string, password: string, saveCookie = true): Promise<RsLogin> => {
        return fetchPost<RqLogin, RsLogin>(
            '/login',
            { username: username, password: password, saveCookie: saveCookie },
            request_token,
            response_token,
            apiLoginUrl,
        ).then((response) => {
            lastMemoized = null;
            return response;
        });
    };

    const postAuthRefresh = (): Promise<RsRefresh> => {
        return fetchGetInner<RsRefresh>('/refresh', response_refresh, apiLoginUrl);
    };

    const logout = mem(
        (): Promise<RsSuccess> => {
            return fetchGet<RsSuccess>('/revoke', response_success, apiLoginUrl);
        },
        { maxAge: 1000 },
    );

    const getUserIdentity = (): Promise<RsIdentity> => {
        return fetchGet<RsIdentity>('/identity', response_identity, apiLoginUrl);
    };

    const getObservations = (input: RqGetObservations) =>
        fetchPost<RqGetObservations, RsGetObservationsTable>(
            '/search',
            input,
            request_observations,
            response_observations_table,
        );

    const getObservationsMap = (input: RqGetObservations) =>
        fetchPost<RqGetObservationsMap, RsGetObservationsMap>(
            '/search/map/points',
            input,
            request_observations_mapPoints,
            response_observations_mapPoints,
        );

    const getObservationsGallery = (input: RqGetObservationsGallery) =>
        fetchPost<RqGetObservationsGallery, RsGetObservationsGallery>(
            '/search/media',
            input,
            request_observations_media,
            response_observations_media,
        );

    const getObservationsLists = (input: RqGetObservationsLists) =>
        fetchPost<RqGetObservationsLists, RsGetObservationsLists>(
            '/search/lists',
            input,
            request_observations_lists,
            response_observations_lists,
        );

    const getExport = (input: RqGetExport, mode: EExportMode) =>
        fetchPost<RqGetExport, RsGetExport>(
            mode === 'items' ? '/search/export' : '/search/lists/export',
            input,
            request_observations_export,
            response_observations_export,
        );

    /**
     * Fetch all taxons. Checks the format of the response and rejects the promise if the response is not valid.
     * Filters out duplicate taxons (those shouldn't be returned by the API in future).
     *
     */
    const getTaxons = () =>
        new Promise<ITaxon[]>((resolve, reject) => {
            fetchGet<ITaxon[]>('/codebook/taxons', z.array(schema_taxon))
                .then((taxons) => {
                    const uniqueIds = [...new Set(taxons.map((taxon) => taxon.id))];
                    const uniqueTaxons = uniqueIds.map((id) => taxons.find((taxon) => taxon.id === id)) as ITaxon[];
                    return resolve(uniqueTaxons);
                })
                .catch((err) => {
                    return reject(err);
                });
        });

    /**
     * Fetch all dictionaries. Checks the format of the response and rejects the promise if the response is not valid.
     */
    const getDicts = () => fetchGet<RsGetDicts>('/codebook/attributes', response_codebook_attributes);

    /**
     * Fetch all projects. Checks the format of the response and rejects the promise if the response is not valid.
     */
    const getProjects = () => fetchGet<RsGetProjects>('/codebook/projects', response_codebook_projects);

    /**
     * Fetch all countries, regions, districts and municipalities. Checks the format of the response and rejects the promise if the response is not valid.
     */
    const getPlaces = () => fetchGet<RsGetPlaces>('/codebook/territorialunits', response_codebook_territorialunits);

    /**
     * Fetch list of observations by id of the list. Checks the format of the response and rejects the promise if the response is not valid.
     * @param id id of the list
     */
    const getList = (id: string) => fetchGet<RsGetListById>(`/list/${id}`, response_list_id);

    /**
     * Fetch list of observations by id of the observation. Checks the format of the response and rejects the promise if the response is not valid.
     * @param id id of the observation from the list
     */
    const getListItem = (id: number | string) => fetchGet<RsGetListById>(`/list/byItem/${id}`, response_list_id);

    const getObsListAPI = (id: string) => fetchGet<RsGetListById>(`/list/${id}`, response_list_id);

    const postItemComment = (
        listId: string,
        itemId: number,
        text: string,
        subject?: string | null,
        parentId?: number,
    ) =>
        fetchPost<RqPostItemComment, RsPostItemComment>(
            `/list/${listId}/item/${itemId}/comment${parentId ? `/${parentId}` : ''}`,
            { text, subject },
            request_list_item_comment,
            response_list_item_comment,
        );

    const updateItemComment = (listId: string, itemId: number, commentId: number, text: string, subject: string) =>
        fetchPost<RqUpdateItemComment, RsUpdateItemComment>(
            `/list/${listId}/item/${itemId}/comment/${commentId}`,
            { text, subject },
            request_list_item_comment,
            response_list_item_comment,
            undefined,
            'PUT',
        );

    const deleteItemComment = (listId: string, itemId: number, commentId: number) =>
        fetchGet<RsSuccess>(
            `/list/${listId}/item/${itemId}/comment/${commentId}`,
            response_success,
            undefined,
            'DELETE',
        );

    const getAllNewsArticles = (language = 'cs', noCache = false) =>
        fetchGet<RsGetAllNewsArticles>(
            `/web/news/${language}`,
            response_web_news_language,
            undefined,
            'GET',
            undefined,
            noCache
                ? {
                      'Cache-Control': 'no-cache',
                  }
                : undefined,
        );

    const getNewsArticleById = (id: string, language = 'cs') =>
        fetchGet<RsGetNewsArticle>(`/web/news/${language}/${id}`, response_web_news_language_id);

    const getHelpSections = (language = 'cs') =>
        fetchGet<RsGetHelpSections>(`/web/help/${language}`, response_web_help_language);

    const getFaqSections = (language = 'cs') =>
        fetchGet<RsGetFaqSections>(`/web/faq/${language}`, response_web_faq_language);

    const getAboutSections = (language = 'cs') =>
        fetchGet<RsGetAboutSections>(`/web/about/${language}`, response_web_about_language);

    const getProjectsInfo = (language = 'cs') =>
        fetchGet<RsGetProjectsInfo>(`/web/projects/${language}`, response_web_projects_language);

    const getUserProfileById = (id: string) =>
        fetchGet<RsGetUserProfileById>(`/user/profile/${id}`, response_user_profile_id);

    const userSendMessage = (userId: string, input: IUserMessage) =>
        fetchPost<IUserMessage, RsSuccess>(`/user/message/${userId}`, input, request_user_message_id, response_success);

    const getUser = () => fetchGet<RsGetUser>('/user', response_user);

    const createList = (input: RqPostObservationsList) =>
        fetchPost<RqPostObservationsList, RsPostList>(`/list`, input, request_list, response_list);

    const updateList = (listId: string, input: RqPutList) =>
        fetchPost<RqPutList, RsPostList>(
            `/list/${listId}`,
            input,
            request_listWithoutItems,
            response_list,
            undefined,
            'PUT',
        );

    const createListItem = (listId: string, input: RqPostListItem) =>
        fetchPost<RqPostListItem, RsPostList>(`/list/${listId}/item`, input, schema_listItem_input, response_list);

    const updateListItem = (listId: string, itemId: number, input: RqPostListItem) =>
        fetchPost<RqPostListItem, RsPostList>(
            `/list/${listId}/item/${itemId}`,
            input,
            schema_listItem_input,
            response_list,
            undefined,
            'PUT',
        );

    const uploadMedia = (mediaFile: File, note: string) => {
        const formData = new FormData();
        formData.append('media', mediaFile);
        formData.append('note', note);

        return fetchPost<FormData, RsPostMedia>('/media', formData);
    };

    const deleteMedia = (uuid: string) => fetchGet<RsSuccess>(`/media/${uuid}`, response_success, undefined, 'DELETE');

    const getMedia = (uuid: string) => fetchGet<RsGetMedia>(`/media/${uuid}`, response_media_id);

    const deleteList = (listId: string) =>
        fetchGet<RsSuccess>(`/list/${listId}`, response_success, undefined, 'DELETE');

    const deleteListItem = (listId: string, itemId: number) =>
        fetchGet<RsGetList>(`/list/${listId}/item/${itemId}`, response_list, undefined, 'DELETE');

    const searchCount = (input: RqGetSearchCount, mode: EExportMode) =>
        fetchPost<RqGetSearchCount, RsGetSearchCount>(
            mode === 'items' ? '/count' : '/count/lists',
            input,
            request_count,
            response_count,
        );

    const geocode = mem((input: ICoordinates) =>
        fetchPost<any, { success: boolean; territorialUnit: { municipalityPart: { id: number; name: string } } }>(
            '/',
            {
                language: 'cs',
                fields: ['territorialUnit'],
                coordinates: {
                    latitude: input[0],
                    longitude: input[1],
                },
            },
            undefined,
            undefined,
            apiGeocodeUrl,
        ),
    );

    const getCharts = (input: RqGetCharts) =>
        fetchPost<RqGetCharts, RsGetCharts>(
            '/analytics/count-date',
            input,
            request_analytics_countDate,
            response_analytics_countDate,
        );

    const postListReport = (listId: string, input: RqPostListReport) =>
        fetchPost<RqPostListReport, RsSuccess>(`/list/${listId}/report`, input, request_list_report, response_success);

    const postListItemReport = (listId: string, itemId: number, input: RqPostListItemReport) =>
        fetchPost<RqPostListItemReport, RsSuccess>(
            `/list/${listId}/item/${itemId}/report`,
            input,
            request_list_report,
            response_success,
        );

    const postUserDefinedFilter = (input: RqPostUserFilter) =>
        fetchPost<RqPostUserFilter, RsSuccessUuid>('/user/filter', input, request_user_filter, response_success_uuid);

    const deleteUserDefinedFilter = (id: string) =>
        fetchGet<RsSuccess>(`/user/filter/${id}`, response_success, undefined, 'DELETE');

    const uploadImport = (importFile: File) => {
        const formData = new FormData();
        formData.append('file', importFile);

        return fetchPost<FormData, RsPostImport>(
            '/import',
            formData,
            undefined,
            response_import,
            undefined,
            undefined,
            [200, 400],
        );
    };

    const getObservationsMapNew = (input: RqGetObservationsMapNew) =>
        fetchPost<RqGetObservationsMapNew, RsGetObservationsMapNew>(
            '/search/map',
            input,
            request_observations_map,
            schema_geojsonFeatureCollection,
        );

    async function _mock_registerUser(user?: IObsUser): Promise<boolean> {
        console.log('registerUser', user);
        await sleep();
        return Math.random() > 0.5;
    }

    async function _mock_requestPasswordReset(email: string): Promise<boolean> {
        console.log('requestPasswordReset', email);
        await sleep();
        return email !== 'fail';
    }

    async function _mock_resetPassword(token: string, newPassword: string): Promise<boolean> {
        console.log('resetPassword', token, newPassword);
        await sleep();
        return token !== 'fail';
    }

    async function _mock_validatePasswordToken(token: string): Promise<boolean> {
        console.log('validatePasswordToken', token);
        await sleep();
        return token !== 'invalid';
    }

    return {
        getObservations,
        getObservationsMap,
        getObservationsLists,
        getExport,
        getObservationsGallery,
        _mock_validatePasswordToken,
        _mock_resetPassword,
        _mock_requestPasswordReset,
        _mock_registerUser,
        login,
        logout,
        getTaxons,
        getDicts,
        getProjects,
        getPlaces,
        getList,
        getListItem,
        getObsListAPI,
        postItemComment,
        updateItemComment,
        deleteItemComment,
        getAllNewsArticles,
        getNewsArticleById,
        getHelpSections,
        getFaqSections,
        getAboutSections,
        getUserProfileById,
        getUserIdentity,
        getUser,
        userSendMessage,
        createList,
        updateList,
        createListItem,
        updateListItem,
        deleteMedia,
        uploadMedia,
        getMedia,
        deleteList,
        deleteListItem,
        searchCount,
        geocode,
        getProjectsInfo,
        getCharts,
        refreshToken,
        postListReport,
        postListItemReport,
        postUserDefinedFilter,
        deleteUserDefinedFilter,
        uploadImport,
        getObservationsMapNew,
    };
};

export default apiFactory;

export type ApiType = ReturnType<typeof apiFactory>;
