import { FunctionComponent, useEffect, useState } from 'react';
import { IMedia } from '../../schemas/interfaces';
import { Dialog, DialogContent, Fab, IconButton, useMediaQuery } from '@mui/material';
import ImageCaption from './ImageCaption';
import { ArrowBackIos, ArrowForwardIos, CloseRounded } from '@mui/icons-material';
import ZoomableImage from './ZoomableImage';

export interface LightboxProps {
    images: IMedia[];
    open?: boolean;
    onClose?: () => void;
    openOnImage?: number;
    hideCaptionIcons?: boolean;
    captions?: (JSX.Element | string)[];
}

const Lightbox: FunctionComponent<LightboxProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<number>(0);
    const isMobile = useMediaQuery('(pointer:coarse)');

    useEffect(() => {
        setOpen(props.open || false);
        setCurrentImage(props.openOnImage || 0);
    }, [props.open, props.openOnImage]);

    if (!open || props.images.length === 0) return null;

    const switchImage = (change: number) => {
        const newImage = currentImage + change;

        if (newImage < 0 || newImage >= props.images.length) return;

        setCurrentImage(newImage);
    };
    const nextImage = () => {
        switchImage(1);
    };
    const prevImage = () => {
        switchImage(-1);
    };

    const closeLightbox = () => {
        setOpen(false);
        if (props.onClose) props.onClose();
    };

    const image = props.images[currentImage];

    return (
        <Dialog
            open={open}
            onClose={closeLightbox}
            maxWidth="lg"
            onKeyDown={(e) => {
                if (e.key === 'ArrowLeft') prevImage();
                if (e.key === 'ArrowRight') nextImage();
            }}
        >
            <DialogContent
                sx={{
                    padding: 0,
                    position: 'relative',
                    '& .MuiIconButton-root:not(.close)': {
                        position: 'absolute',
                        top: '0',
                        bottom: '0',
                        width: '10%',
                        minWidth: '40px',
                        borderRadius: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        color: '#fff',
                        '&.Mui-disabled': {
                            opacity: 0,
                        },
                    },
                }}
            >
                {open && (
                    <>
                        <ZoomableImage url={image.url} altText={image.note} width="100%" height="calc(100vh - 64px)" />
                        <IconButton onClick={() => prevImage()} size="large" disabled={currentImage == 0}>
                            <ArrowBackIos fontSize="large" />
                        </IconButton>
                        <IconButton
                            onClick={() => nextImage()}
                            size="large"
                            sx={{ right: 0 }}
                            disabled={currentImage == props.images.length - 1}
                        >
                            <ArrowForwardIos fontSize="large" />
                        </IconButton>
                    </>
                )}
                <Fab
                    size={isMobile ? 'small' : 'medium'}
                    sx={{
                        position: 'absolute',
                        right: '0.5rem',
                        top: '0.5rem',
                        color: 'text.primary',
                        bgcolor: 'background.paper',
                    }}
                    onClick={closeLightbox}
                    className="close"
                >
                    <CloseRounded fontSize="large" />
                </Fab>
            </DialogContent>
            <ImageCaption image={image} hideIcons caption={props.captions && props.captions[currentImage]} />
        </Dialog>
    );
};

export default Lightbox;
