import { Box } from '@mui/material';
import { FC } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ZoomableImage: FC<{ url: string; altText?: string | null; width?: string; height?: string }> = ({
    url,
    altText,
    width,
    height,
}) => {
    return (
        <TransformWrapper key={url}>
            <TransformComponent>
                <Box
                    sx={{
                        width: width,
                        height: height,
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        bgcolor: 'primary.50',
                        pointerEvents: 'none',
                    }}
                >
                    <img
                        src={url}
                        alt={altText || ''}
                        style={{
                            display: 'block',
                            width: '100%',
                        }}
                    />
                </Box>
            </TransformComponent>
        </TransformWrapper>
    );
};

export default ZoomableImage;
